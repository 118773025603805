<template>
	<v-container fluid style="max-width: 1600px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('events.schedule') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col cols="12" lg="8" md="12">
				<v-subheader>
					{{ $t('settings.numberOfApplications') }}: {{ attendances.length }}/{{ currentUser.limits ? currentUser.limits.attendances : '???' }}
				</v-subheader>
			</v-col>
			<v-col cols="12" lg="4" md="12">
				<v-btn color="info" rounded :to="{ name: 'Events' }" class="float-right">
					{{ $t('events.searchEvents') }}
				</v-btn>
			</v-col>
		</v-row>

		<div class="d-flex align-center">
			<v-icon class="mx-4"> mdi-magnify </v-icon>
			<v-text-field
				v-model="title"
				:placeholder="`${$t('search.search')}: ${$t('auth.name')}, ${$t('companies.company')}, ${$t('companies.location')}`"
			/>
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4"> mdi-filter </v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4"> mdi-close </v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.eventTypes"
									:label="$t('events.eventType')"
									:items="availableEventTypes"
									item-text="name"
									item-value="id"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-text-field v-model="filters.location" :label="$t('companies.location')" outlined rounded hide-details="auto" class="pb-0" />
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.companies"
									:label="$t('companies.company')"
									:items="availableCompanies"
									item-text="name"
									item-value="name"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
									clearable
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>

			<template v-else-if="attendances.length">
				<template v-for="attendance in filteredAttendances">
					<MyAttendanceCard :key="attendance.id" :attendance="attendance" />
				</template>

				<template v-if="!filteredAttendances.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						{{ $t('events.myScheduleHelp') }}
					</h4>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('events.schedule'),
		}
	},
	data() {
		return {
			loading: false,
			filtersEditMode: false,
			title: '',
			filters: {
				eventTypes: [],
				companies: [],
				location: '',
			},
		}
	},
	components: {
		MyAttendanceCard: () => import('@/components/attendances/MyAttendanceCard.vue'),
	},
	computed: {
		filteredAttendances() {
			return this.attendances.filter(
				(attendance) =>
					(!this.filters.eventTypes.length || this.filters.eventTypes.map((e) => e.id).includes(attendance.event.type.id)) &&
					(!this.filters.companies.length ||
						this.filters.companies.map((e) => e.name).includes(attendance.event.company.name) ||
						this.filters.companies.map((e) => e.name).includes(attendance.event.companyName)) &&
					removeAccents(attendance.event.info.location).toLowerCase().includes(removeAccents(this.filters.location).toLowerCase()) &&
					(removeAccents(attendance.event.title).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(attendance.event.info.location).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(attendance.event.company.name).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(attendance.event.companyName).toLowerCase().includes(removeAccents(this.title).toLowerCase()))
			)
		},
		...mapGetters({
			currentUser: 'user/currentUser',
			attendances: 'attendances/attendances',
			availableCompanies: 'companies/companies',
			availableEventTypes: 'events/eventTypes',
		}),
	},
	created() {
		this.loading = true
		this.fetchEventTypes()
		this.fetchCompaniesFilter()
		this.fetchAttendances().then(() => {
			this.loading = false
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('companies', ['fetchCompaniesFilter']),
		...mapActions('events', ['fetchEventTypes']),
		...mapActions('attendances', ['fetchAttendances']),
	},
}
</script>
